// LandingPage.js

import React from 'react';
import './LandingPage.css'; // Import your styles
import underConstructionImage from './under-construction.jpg'; // Import the image

const LandingPage = () => {
  return (
    <div className="landing-page-container">
      <header>
        <h1>Life Beyond Addiction</h1>
        <h2>Alex Calder</h2>
      </header>
      <section className="content-section">
        <img src={underConstructionImage} alt="Under Construction" />
        <h2>Website Under Construction</h2>
        <p>
          Thank you for your patience. I am working on creating a space to share about my research and clinical work.
        </p>
      </section>
      <footer>
        <p>&copy; {new Date().getFullYear()} Alex Calder. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
